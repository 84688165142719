import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { logout } from "../firebasefunctions/functions";

export default function Main() {
    const handleLogout = () => {
        logout();
    };
  return (
    <>
      <div className="text-sky-300 font-cub text-6xl text-center">
        Welcome to TechBret's Protected Routes
      </div>
      <div className=" font-cub text-4xl text-center">
        This is a subscriber only area
      </div>

      <div className=" font-cub text-xl text-center mt-12">
        You can only see this page if you are logged in
      </div>
      <p className="font-cub text-xl text-center">
        To demonstrate some of the skills and knowledge that I have gained over the years, I have created a few games for you to play.
      </p>
      <div className=" font-cub text-xl text-center flex items-center justify-center mt-12">
      <FaArrowLeft className="text-4xl text-sky-300 "/> You can find them in the menu to the left
      </div>
        <div className=" font-cub text-2xl text-center">
            Enjoy!
        </div>
        <div className="mx-auto text-center mt-24">
            <button className="bg-yellow-300 hover:bg-sky-400 text-zinc-950 font-cub py-2 px-4 rounded-full mt-12"
                onClick={handleLogout}
            >
                Logout
            </button>
        </div>
    </>
  );
}
