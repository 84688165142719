import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa6";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Quiz() {
  const [selected, setSelected] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [complete, setComplete] = useState(false);

  const questions = [
    {
      question: "What is the capital of France?",
      answers: ["Paris", "New York", "London", "Dublin"],
      correct: 0,
    },
    {
      question: "Who is CEO of Tesla?",
      answers: ["Jeff Bezos", "Elon Musk", "Bill Gates", "Tony Stark"],
      correct: 1,
    },
    {
      question: "The iPhone was created by which company?",
      answers: ["Apple", "Intel", "Amazon", "Microsoft"],
      correct: 0,
    },
    {
      question: "How many Harry Potter books are there?",
      answers: ["1", "4", "6", "7"],
      correct: 3,
    },
  ];

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      if (selected === questions[currentQuestion]?.correct) {
        setScore(score + 1);
      }
      setCurrentQuestion(currentQuestion + 1);
      setSelected(null);
    } else {
      // Step 2: Update the 'complete' state when all questions are answered
      if (selected === questions[currentQuestion]?.correct) {
        setScore(score + 1);
      }
      setComplete(true);
      setCurrentQuestion(0);
      setSelected(null);
    }
  };

  const handleTryAgain = () => {
    setComplete(false);
    setScore(0);
  }


 
  return (
    <div className="grid sm:grid-cols-4 gap-4 gap-y-10 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2 mt-36">
      <div className="col-span-2">
        <h1 className="text-4xl  font-cub">Quiz</h1>
        <p className="font-sof text-gray-100 text-lg">
          This is a simple quiz app that I built using React and Tailwind CSS.
          It uses the Headless UI RadioGroup component to create the quiz
          functionality. The questions are stored in an array of objects, and
          the correct answer is checked against the selected answer to determine
          the score. The quiz is completed when all questions have been
          answered.
        </p>
        <h3 className="text-2xl font-cub mt-12">Why I built this</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          I wanted to build a quiz app that would be easy to update and add
          questions to. I also wanted to use the Headless UI RadioGroup
          component to create the quiz functionality to give it a more
          interactive feel.
        </p>
        <p className="font-sof text-lg text-gray-100 text-left mt-4">
          Questions can be stored in a database and retrieved using an API call
          to make the quiz dynamic, or if you have a small number of questions, or static 
          questions, you can store them in an array of objects like I did.
        </p>
      </div>
      <div className="col-span-2 font-cub">
        {complete ? (
            <div className="bg-zinc-950 h-auto text-white">
                <div className="bg-zinc-950 h-ful p-4 text-white">
                <h1 className="text-2xl font-bold text-center bg-zinc-800 p-2 rounded-md">
                    Quiz: <span className="text-orange-500">Random Questions</span>
                </h1>
    
                <h3 className="text-4xl font-bold mt-6 text-center">
                 
                    Score: {score / questions.length * 100}%
                </h3>
                <div className="mt-4">
                    <button
                    className="bg-orange-700 text-white rounded-md shadow-md p-4 mt-4 ml-auto w-full mb-14 flex items-center justify-center"
                    onClick={() => handleTryAgain()}
                    >
                    Try Again <FaArrowRight className="ml-2" />
                    </button>
                </div>
                </div>
            </div>
        ) : (
            <>
            {questions?.length > 0 ? (
              <div className="bg-zinc-950 h-auto text-white">
                <div className="bg-zinc-950 h-ful p-4 text-white">
                  <h1 className="text-2xl font-bold text-center bg-zinc-800 p-2 rounded-md">
                    Quiz:{" "}
                    <span className="text-orange-500">Random Questions</span>
                  </h1>

                  <h3 className="text-xl font-bold mt-6">
                    Question {currentQuestion + 1} of {questions.length}
                  </h3>
                 
                  <div className="mt-4 text-3xl">
                    {questions[currentQuestion]?.question}
                  </div>
                  <div className="mt-4">
                    <RadioGroup value={selected} onChange={setSelected}>
                      <RadioGroup.Label className="sr-only">
                        Server size
                      </RadioGroup.Label>
                      <div className="space-y-2">
                        {questions[currentQuestion]?.answers?.map(
                          (answer, index) => (
                            <RadioGroup.Option
                              key={answer}
                              value={index}
                              className={({ active, checked }) =>
                                classNames(
                                  active ? "" : "",
                                  checked
                                    ? "bg-zinc-950 bg-opacity-75 border border-orange-300 text-white"
                                    : "bg-zinc-900",
                                  "relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center">
                                      <div className="text-lg">
                                        <RadioGroup.Label
                                          as="p"
                                          className={classNames(
                                            checked
                                              ? "text-white"
                                              : "text-zinc-200",
                                            "font-medium"
                                          )}
                                        >
                                          {answer}
                                        </RadioGroup.Label>
                                      </div>
                                    </div>
                                    {checked && (
                                      <div className="flex-shrink-0 text-white">
                                        <FaCheck className="w-6 h-6" />
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </RadioGroup.Option>
                          )
                        )}
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="">
                    <button
                      className="bg-orange-700 text-white rounded-md shadow-md p-4 mt-4 ml-auto w-full mb-14 flex items-center justify-center"
                      onClick={() => handleNextQuestion()}
                    >
                      Next Question <FaArrowRight className="ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>Waiting...</div>
            )}
          </>
        )}
        
      </div>
    </div>
  );
}
