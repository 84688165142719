import React, { useCallback, useRef } from "react";
import { FaPhotoFilm } from "react-icons/fa6";
import { MdOutlineDelete } from "react-icons/md";

export default function ImageUploader() {
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [selected, setSelected] = React.useState(0);
  const fileInputRef = useRef(null);

  const handleDeletePhoto = (index) => {
    // Create a copy of the selectedFile array using spread operator
    const updatedFiles = [...selectedFile];

    // Remove the photo at the given index from the updatedFiles array
    updatedFiles.splice(index, 1);

    // Update the state with the updatedFiles array
    setSelectedFile(updatedFiles);
  };

  const handleSelect = useCallback(
    (index) => {
      setSelected(index);
      const newFiles = [...selectedFile];
      const selectedFiles = newFiles.splice(index, 1)[0];
      newFiles.unshift(selectedFiles);
      setSelectedFile(newFiles);
    },
    [selectedFile, setSelectedFile]
  );
  const createThumbnail = (imageFile, maxSize) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], imageFile.name, { type: imageFile.type }));
          }, imageFile.type);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(imageFile);
    });
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const newFilesArray = [];

    // Convert the FileList to an array and append it to the existing selectedFile array
    for (let i = 0; i < files.length; i++) {
      const thumbnail = await createThumbnail(files[i], 700); // Specify your desired max size for the thumbnail
      newFilesArray.push(thumbnail);
    }

    // Update the state to include the new photos
    setSelectedFile((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...newFilesArray,
    ]);
  };

  return (
    <div className="grid sm:grid-cols-4 gap-4 gap-y-10 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2 mt-24">
      <div className="col-span-2">
        <h1 className="text-4xl  font-cub">Upload Images</h1>
        <p className="font-sof text-gray-100 text-lg">
          Most websites have a need to upload images, documents, and more. This
          example uploads images to a Google Bucket and display them on the
          page. The images are stored in a database and can be retrieved later.
          This is a great way to store images for a website.
        </p>
        <h3 className="text-2xl font-cub mt-12">Why is this important?</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          In the realm of web development, the task of uploading and displaying
          various media files, such as images and documents, is a common
          requirement. My solution takes this functionality to the next level by
          seamlessly integrating with Google Cloud Storage, providing a robust
          and efficient way to manage and showcase multimedia content on your
          website
        </p>
        <h3 className="text-2xl font-cub mt-12">
          Here's a breakdown of how it operates:
        </h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          <ol className="list-decimal">
            <li>
              <span className="font-bold">Image Upload:</span>
              <div className="ml-4">
                Users can effortlessly upload images, documents, or other files
                through this system. Behind the scenes, these files are securely
                transferred and stored in a Google Cloud Storage bucket. This
                not only ensures the safety and scalability of your data but
                also enables easy access from anywhere in the world.
              </div>
            </li>
            <li>
              <span className="font-bold">Database Integration:</span>
              <div className="ml-4">
                To enable easy retrieval and management, I store metadata and
                references to these uploaded files in the TechBret database.
                This database serves as the central repository for organizing
                and accessing your multimedia assets on this site. Each file is
                associated with relevant information, such as titles,
                descriptions, and timestamps.
              </div>
            </li>
            <li>
              <span className="font-bold">Dynamic Display:</span>
              <div className="ml-4">
                Now comes the exciting part. When it comes to displaying
                multiple images efficiently on a webpage, it can be a
                performance challenge. To address this, my system employs a
                social media based strategy. It generates image previews,
                allowing users to see a smaller, optimized version of the image
                without the need to load the full-sized file upfront. This
                minimizes page load times and optimizes user experience. If
                there are more than three images, a "View More" button is
                displayed over the top of the third image, which allows users to
                see the rest of the images in a modal.
              </div>
            </li>
            <li>
              <span className="font-bold">On-Demand Loading:</span>
              <div className="ml-4">
                To further enhance the user experience, our solution
                incorporates a 'lazy loading' mechanism. It means that when a
                user clicks on a preview image, only then does the system fetch
                and load the full image. This approach conserves bandwidth and
                ensures quick page responsiveness, even when dealing with a
                substantial volume of multimedia content.
              </div>
            </li>
          </ol>
        </p>
        <p className="font-sof text-lg text-gray-100 text-left mt-4"></p>
        <p className="font-sof text-lg text-gray-100 text-left mt-4"></p>
      </div>
      <div className="col-span-2">
        <div className="col-span-full">
          {selectedFile && selectedFile.length > 0 ? (
            <div className="grid grid-cols-2 gap-1 mb-4 mt-2">
              {selectedFile && selectedFile.length > 0 && (
                <img
                  className="w-full h-64 object-cover object-center border-st-dark-900 border-4"
                  src={URL.createObjectURL(selectedFile[0])}
                  alt="Preview"
                />
              )}

              {selectedFile.slice(1).map((file, index) => (
                <div key={index} className="relative">
                  <img
                    className="w-full h-64 object-cover object-center"
                    key={file.name}
                    src={URL.createObjectURL(file)}
                    alt="Preview"
                  />
                  {/* Add delete button */}
                  <button
                    className="absolute top-0 right-0 p-1 m-1 bg-red-500 rounded-full text-white"
                    onClick={() => handleDeletePhoto(index + 1)} // Add 1 to the index to account for the skipped first element
                  >
                    <MdOutlineDelete className="h-5 w-5" />
                  </button>
                  <button
                    className="absolute bottom-2 right-2 bg-gray-900 hover:bg-gray-700 rounded-full text-white px-4 pb-1"
                    onClick={() => handleSelect(index + 1)}
                  >
                    <span className="text-white text-xs font-bold ">
                      Select as Main Photo
                    </span>
                  </button>
                </div>
              ))}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
                multiple
              />
              <button
                type="button"
                className="relative block w-full h-64 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => fileInputRef.current.click()}
              >
                <svg
                  className="mx-auto h-12 w-12 text-white"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="mt-2 block text-sm font-semibold font-cub">
                  Add More Photos
                </span>
              </button>
            </div>
          ) : (
            <>
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-white"
              >
                Upload Images
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
                multiple
              />
              <button
                className="mt-2 w-full flex justify-center rounded-lg text-gray-500 hover:text-white border border-dashed border-white/25 hover:border-sky-300 px-6 py-10"
                onClick={() => fileInputRef.current.click()}
              >
                <div className="text-center">
                  <FaPhotoFilm
                    className="mx-auto h-12 w-12 "
                    aria-hidden="true"
                  />

                  <div className="mt-4 flex text-sm leading-6 text-gray-400">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md  font-semibold text-white  "
                    >
                      <span>Upload files</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">to test the system</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-400">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
