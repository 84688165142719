import React from "react";
import PriceCalculator from "../samples/PriceCalculator";
import Graphs from "../samples/Graphs";
import ImageUploader from "../samples/ImageUploader";
import Contracts from "../samples/Contracts";
import VideoRecorder from "../samples/VideoRecorder";
import SecuredRouting from "../samples/SecuredRouting";
import MapsAndPins from "../samples/MapsAndPins";
import Quiz from "../samples/Quiz";
import DatabaseOperations from "../samples/DatabaseOperations";

function Divider({ name }) {
  return (
    <div className="relative my-20">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-zinc-950 px-3 font-cub text-3xl leading-6">
          {name}
        </span>
      </div>
    </div>
  );
}

export default function Samples({ display }) {
  const components = {
    "Pricing Calculator": <PriceCalculator />,
    Graphs: <Graphs />,
    "Image Uploader": <ImageUploader />,
    Contracts: <Contracts />,
    "Secured Routing": <SecuredRouting />,
    "Video Recorder": <VideoRecorder />,
    "Maps and Pins": <MapsAndPins />,
    Quiz: <Quiz />,
    "Database Operations": <DatabaseOperations />,
  };

  return (
    <div className="px-4 pb-24">
      <Divider name={display} />
      {components[display] || <PriceCalculator />}
    </div>
  );
}
