import React, { useState } from "react";
import { contactMessage } from "../firebasefunctions/functions";
import { UserAuth } from "../context/AuthContext";

export default function Contact() {
  const { messageSubmitted, setMessageSubmitted } = UserAuth();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleMessage = () => {
    contactMessage(name, email, phoneNumber, message);
    setMessageSubmitted(true);
  };

  return (
    <div id="contact">
      {messageSubmitted ? (
        <div className="px-6 pt-24 sm:px-6 lg:px-8 font-cub">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className="text-5xl font-bold text-sky-400 sm:text-6xl font-cub">
              Thank You
            </h1>
            <h2 className="text-3xl font-bold text-white sm:text-4xl font-cub mt-2">
              I'll be in touch soon<span className="text-sky-400">!</span>
            </h2>
            <p className="mt-2  text-lg text-zinc-200 font-sof">
              Thank you for reaching out!
            </p>
          </div>
        </div>
      ) : (
        <div className="px-6 pt-24 sm:px-6 lg:px-8 font-cub">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className="text-5xl font-bold text-sky-400 sm:text-6xl font-cub">
              Contact Me
            </h1>
            <h2 className="text-3xl font-bold text-white sm:text-4xl font-cub mt-2">
              I'd love to hear from you<span className="text-sky-400">!</span>
            </h2>
            <p className="mt-2  text-lg text-zinc-200 font-sof">
              If you have any questions or comments, please let me know below.
            </p>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-white"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-white"
              >
                Phone Number
              </label>
              <div className="mt-2">
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  autoComplete="phone"
                  required
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-white"
              >
                Message
              </label>
              <div className="mt-2">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                  defaultValue={""}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-sky-500 px-3 py-3 text-2xl font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500 h-12"
                onClick={handleMessage}
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
