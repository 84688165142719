import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase/firebase";

const UserContext = createContext();

export const UserAuth = () => {
  return useContext(UserContext);
};

export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState({
    userType: "user",
  });
  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [messageSubmitted, setMessageSubmitted] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setIsLoggedOut(false);
        const docRef = doc(db, "users", currentUser.uid);
        const unsubscribeSnapshot = onSnapshot(docRef, (doc) => {
          setUser(doc.data());
        });
        console.log("It ran again");
        return () => {
          unsubscribeSnapshot(); // Clean up the onSnapshot listener
        };
      } else {
        setIsLoggedOut(true);
      }
    });
    return () => {
      unsubscribe(); // Clean up the onAuthStateChanged listener
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isLoggedOut,
        isLoading,
        setIsLoading,
        formSubmitted,
        setFormSubmitted,
        messageSubmitted,
        setMessageSubmitted,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
