import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  BarElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { useState } from "react";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function NumberInput({ value, onChange, placeholder, color, cs }) {
  const handleChange = (e) => {
    const input = e.target.value;
    // Check if the input is a valid number (including negative numbers)
    if (/^-?\d*\.?\d*$/.test(input) || input === "") {
      onChange(input);
    }
  };

  return (
    <input
      className={`rounded-lg p-2 bg-gray-800 text-gray-100 border ${color} ${cs}`}
      type="text"
      placeholder={placeholder}
      aria-describedby="price-currency"
      value={value}
      onChange={handleChange}
    />
  );
}

export default function Graphs() {
  const [totalUnitsSold, setTotalUnitsSold] = useState(null);
  const [costPerUnit, setCostPerUnit] = useState(null);
  const [pricePerUnit, setPricePerUnit] = useState(null);
  const [totalCostOfMarketing, setTotalCostOfMarketing] = useState(null);
  const [numberOfCustomers, setNumberOfCustomers] = useState(null);
  const [shippingCostPerUnit, setShippingCostPerUnit] = useState(null);
  const [shippingChargePerUnit, setShippingChargePerUnit] = useState(null);
  const [otherExpenses, setOtherExpenses] = useState(null);
  const [totalMonths, setTotalMonths] = useState(1);
  const [payroll, setPayroll] = useState(null);

  const [revenue, setRevenue] = useState(0);
  const [revenueCost, setRevenueCost] = useState(0);
  const [shippingRevenue, setShippingRevenue] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [netRevenue, setNetRevenue] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [netPerCustomer, setNetPerCustomer] = useState(0);
  const [costPerCustomer, setCostPerCustomer] = useState(0);
  const [costPerMonth, setCostPerMonth] = useState(0);
  const [costPerUnitSold, setCostPerUnitSold] = useState(0);
  const [costPerUnitSoldWithShipping, setCostPerUnitSoldWithShipping] =
    useState(0);
  const [netRevenueWithShipping, setNetRevenueWithShipping] = useState(0);
  const [totalExpensesWithShipping, setTotalExpensesWithShipping] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRevenuePerMonth, setTotalRevenuePerMonth] = useState(0);
  const [displayGraphs, setDisplayGraphs] = useState(false);

  const scrollToPortfolio = (page) => {
    const portfolioSection = document.getElementById(page);
    if (portfolioSection) {
      portfolioSection.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "P&L Breakdown",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            // Format the tooltip label with a "$" sign
            return '$' + context.parsed.y.toFixed(2); // Assuming you want two decimal places
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            // Format the y-axis label with a "$" sign
            return '$' + value.toFixed(2); // Assuming you want two decimal places
          },
        },  
      },
    },
  };
  
  

  const labels = ["Revenue", "Expenses", "Net", "Shipping"]

  const [data00, setData00] = useState({
    labels,
    datasets: [
      {
        label: "Expenses",
        data: [25],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Revenue",
        data: [25],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
      {
        label: "Net",
        data: [25],
        backgroundColor: "rgba(255, 206, 86, 0.5)",
      },
      {
        label: "Shipping",
        data: [25],
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  });
  const [data01, setData01] = useState({
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [netRevenueWithShipping, totalExpensesWithShipping],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const calculateRevenue = (e) => {
    e.preventDefault();
    console.log("Calculating Revenue");
    const revenue = parseInt(totalUnitsSold) * parseInt(pricePerUnit);
    const revenueCost = parseInt(totalUnitsSold) * parseInt(costPerUnit);
    const netRevenue = parseInt(revenue) - parseInt(revenueCost);

    const shippingRevenue =
      parseInt(totalUnitsSold) * parseInt(shippingChargePerUnit);
    const shippingCost =
      parseInt(totalUnitsSold) * parseInt(shippingCostPerUnit);

    const netRevenueWithShipping = netRevenue + shippingRevenue;

    const totalExpenses =
      parseInt(totalCostOfMarketing) +
      parseInt(otherExpenses) +
      parseInt(payroll);
    const totalExpensesWithShipping =
      parseInt(totalExpenses) + parseInt(shippingCost);

    const costPerCustomer =
      parseInt(totalExpenses) / parseInt(numberOfCustomers);
    const costPerMonth = parseInt(totalExpenses) / parseInt(totalMonths);
    const costPerUnitSold = parseInt(totalExpenses) / parseInt(totalUnitsSold);
    const costPerUnitSoldWithShipping =
      parseInt(totalExpenses) / (parseInt(totalUnitsSold) * 2);
    const netPerCustomer = netRevenue / numberOfCustomers;

    const total =
      parseInt(netRevenueWithShipping) - parseInt(totalExpensesWithShipping);
    const totalRevenuePerMonth = parseInt(total) / parseInt(totalMonths);

    setRevenue(revenue || 0);
    setRevenueCost(revenueCost || 0);
    setNetRevenue(netRevenue || 0);
    setShippingRevenue(shippingRevenue || 0);
    setShippingCost(shippingCost || 0);
    setTotalExpenses(totalExpenses || 0);
    setCostPerCustomer(costPerCustomer || 0);
    setCostPerMonth(costPerMonth || 0);
    setCostPerUnitSold(costPerUnitSold || 0);
    setCostPerUnitSoldWithShipping(costPerUnitSoldWithShipping || 0);
    setNetPerCustomer(netPerCustomer || 0);
    setNetRevenueWithShipping(netRevenueWithShipping || 0);
    setTotalExpensesWithShipping(totalExpensesWithShipping || 0);
    setTotalRevenue(total || 0);
    setTotalRevenuePerMonth(totalRevenuePerMonth);

    setData01({
      labels: [
        "Product Cost",
        "Shipping Cost",
        "Marketing Cost",
        "Other",
        "Payroll",
      ],
      datasets: [
        {
          label: "Expenses",
          data: [
            revenueCost,
            shippingCost,
            totalCostOfMarketing,
            otherExpenses,
            payroll,
          ],
          backgroundColor: [
            "#0088FE",
            "#00C49F",
            "#FFBB28",
            "#FF8042",
            "#FF8042",
          ],
          borderColor: ["#0088FF", "#00C49F", "#FFBB28", "#FF8042", "#FF8042"],
          borderWidth: 1,
        },
      ],
    });

    setData00({
      labels: ["Breakdown"],
      datasets: [
        {
          label: "Revenue",
          data: [
            netRevenueWithShipping,
          ],
          backgroundColor: ["#86efac"],
          borderColor: ["#16a34a"],
          borderWidth: 1,
        },
        {
          label: "Expenses",
          data: [
            totalExpensesWithShipping,
          ],
          backgroundColor: ["#fca5a5"],
          borderColor: [ "#dc2626"],
          borderWidth: 1,
        },
        {
          label: "Net",
          data: [
            total,
          ],
          backgroundColor: ["#86efac"],
          borderColor: ["#16a34a"],
          borderWidth: 1,
        },
        {
          label: "Shipping",
          data: [
            shippingCost,
          ],
          backgroundColor: ["#fca5a5"],
          borderColor: ["#dc2626"],
          borderWidth: 1,
        },

      ],
    });

    setDisplayGraphs(true);
    scrollToPortfolio('graphs');
  };

 
  return (
    <div>
      {displayGraphs && (
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 max-w-7xl mx-auto" id="graphs">
          <div className="font-cub col-span-2 sm:col-span-1">
            <Doughnut data={data01} />
          </div>
          <div className="font-cub col-span-2 sm:col-span-1">
            <Bar options={options} data={data00} />
          </div>
          <div className="grid sm:grid-cols-3 grid-cols-1 col-span-2 gap-3">
          <div className="rounded-xl border-2 border-indigo-400 shadow">
            <div class="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
              <h3 class="tracking-tight text-sm text-indigo-400 font-medium">
                Cost Per Customer
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="h-4 w-4 text-muted-foreground"
              >
                <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
              </svg>
            </div>
            <div class="p-6 pt-0">
              <div class="text-2xl font-bold font font-mono">
                ${costPerCustomer?.toFixed(2)}
              </div>
              <p class="text-xs text-muted-foreground text-green-400">
                -20.1% from last month
              </p>
            </div>
          </div>
          <div className="rounded-xl border-2 border-sky-400 shadow ">
            <div class="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
              <h3 class="tracking-tight text-sm font-medium text-sky-400">Cost Per Month</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="h-4 w-4 text-muted-foreground"
              >
                <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
              </svg>
            </div>
            <div class="p-6 pt-0">
              <div class="text-2xl font-bold font font-mono">
                ${costPerMonth?.toFixed(2)}
              </div>
              <p class="text-xs text-muted-foreground text-red-400">+3.6% from last month</p>
            </div>
          </div>
          <div className="rounded-xl border-2 border-blue-500 shadow ">
            <div class="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
              <h3 class="tracking-tight text-sm font-medium text-blue-400">
                Cost Per Unit Sold
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="h-4 w-4 text-muted-foreground"
              >
                <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
              </svg>
            </div>
            <div class="p-6 pt-0">
              <div class="text-2xl font-bold font font-mono">
                ${costPerUnitSold?.toFixed(2)}
              </div>
              <p class="text-xs text-muted-foreground text-red-400">
                +20.1% from last month
              </p>
            </div>
          </div>
          </div>
        </div>
      )}

      <div className="grid sm:grid-cols-4 gap-4 gap-y-10 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2 mt-24">
        <div className="col-span-2">
          <h1 className="text-4xl  font-cub">Graphs and Data</h1>
          <p className="font-sof text-gray-100 ">
            Data visualization is a powerful tool for understanding trends and
            patterns. I've used a few different libraries to create graphs and
            charts for my projects. Here are a few examples of my work.
          </p>
          <h3 className="text-2xl font-cub mt-12">Why does this matter?</h3>
          <p className="font-sof text-lg text-gray-100 text-left">
            On almost every project I've worked on, I've had to display data in
            a way that is easy to understand. Graphs and charts are a great way
            to do this.
          </p>
          <h3 className="text-2xl font-cub mt-12">This Example:</h3>
          <p className="font-sof text-lg text-gray-100 text-left">
            In this demonstration, you as the user, can input data into the form
            and see the results displayed in the graph. This is a great way to
            see how the data is being processed and displayed.
          </p>
          <p className="font-sof text-lg text-gray-100 text-left mt-4"></p>
          <p className="font-sof text-lg text-gray-100 text-left mt-4"></p>
        </div>
        <div className="col-span-2">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h1 className="text-4xl  font-cub">Revenue</h1>
            </div>
            <div>
              <h1 className="text-4xl  font-cub">Expense</h1>
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Total Units Sold
              </label>

              <NumberInput
                value={totalUnitsSold}
                onChange={(value) => setTotalUnitsSold(value)}
                placeholder="0"
                color="border-green-300"
              />
            </div>

            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Cost Per Unit
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                  value={costPerUnit}
                  onChange={(value) => setCostPerUnit(value)}
                  placeholder="0.00"
                  color="border-red-300"
                  cs="w-full pl-7"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Price Per Unit
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                  value={pricePerUnit}
                  onChange={(value) => setPricePerUnit(value)}
                  placeholder="0.00"
                  color="border-green-300"
                  cs="w-full pl-7"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Total Cost of Marketing
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                  value={totalCostOfMarketing}
                  onChange={(value) => setTotalCostOfMarketing(value)}
                  placeholder="0.00"
                  color="border-red-300"
                  cs="w-full pl-7"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Number of Customers
              </label>

              <NumberInput
                value={numberOfCustomers}
                onChange={(value) => setNumberOfCustomers(value)}
                placeholder="0"
                color="border-green-300"
              />
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Shipping Cost Per Unit
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                  value={shippingCostPerUnit}
                  onChange={(value) => setShippingCostPerUnit(value)}
                  placeholder="0.00"
                  color="border-red-300"
                  cs="w-full pl-7"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Shipping Charge Per Unit
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                  value={shippingChargePerUnit}
                  onChange={(value) => setShippingChargePerUnit(value)}
                  placeholder="0.00"
                  color="border-green-300"
                  cs="w-full pl-7"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Other Expenses
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                  value={otherExpenses}
                  onChange={(value) => setOtherExpenses(value)}
                  placeholder="0.00"
                  color="border-red-300"
                  cs="w-full pl-7"
                />
              </div>
            </div>
            <div className="col-span-2 mt-2">
              <h1 className="text-4xl  font-cub">Variables</h1>
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">
                Total Months
              </label>

              <NumberInput
                value={totalMonths}
                onChange={(value) => setTotalMonths(value)}
                placeholder="0"
                color="border-green-300"
              />
            </div>
            <div className="flex flex-col">
              <label className="font-sof text-gray-100 text-xs">Payroll</label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                  value={payroll}
                  onChange={(value) => setPayroll(value)}
                  placeholder="0.00"
                  color="border-red-300"
                  cs="w-full pl-7"
                />
              </div>
            </div>
            <div className="col-span-2">
              <button
                className="bg-green-600 hover:bg-green-400 rounded-lg p-2 text-gray-100 font-cub w-full"
                onClick={calculateRevenue}
              >
                Calculate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
