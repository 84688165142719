import { useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import bret from "../assets/images/bret.jpg";
import { leaveEmail } from "../firebasefunctions/functions";
import { UserAuth } from "../context/AuthContext";

export default function Hero() {
  const {formSubmitted, setFormSubmitted} = UserAuth();
  const [email, setEmail] = useState("");
  


  const handleEmail = (e) => {
    e.preventDefault();
    leaveEmail(email);
    setFormSubmitted(true);
  };

  const scrollToPortfolio = (page) => {
    const portfolioSection = document.getElementById(page);
    if (portfolioSection) {
      portfolioSection.scrollIntoView({ behavior: 'smooth' });
    }
  };




  return (
    <main>
      <div className="pt-10 sm:pt-16 lg:overflow-hidden lg:pb-14 lg:pt-8">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md px-6 sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
              <div className="lg:py-24">
                <div className="hidden sm:mb-5 sm:flex sm:justify-center lg:justify-start">
                  <button                    
                    className="flex items-center rounded-md bg-zinc-700 p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                    onClick={() => scrollToPortfolio("contact")}
                  >
                    <span className="rounded-md bg-sky-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                      I'm Available For Hire
                    </span>
                    <span className="ml-4 text-sm">Contact me</span>
                    <FaChevronRight
                      className="ml-2 h-5 w-5 text-gray-200"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <h1 className="text-4xl font-bold text-white sm:text-6xl lg:mt-6 xl:text-6xl font-cub">
                  <span className="block">
                    Bret Johnson
                    </span>
                  <span className="block text-sky-400 sm:text-3xl">
                    Software Engineer
                  </span>
                  <span className="block text-indigo-500 sm:text-3xl">
                    Full-Stack Developer
                    </span>
                </h1>
                <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl font-sof">
                    I'm a full-stack developer with a passion for building beautiful, functional, and responsive web applications. I'm currently seeking a full-time position as a software developer.
                </p>
                {formSubmitted ? (
                    <div className="mt-10">
                        <h1 className="text-3xl font-bold text-white sm:text-4xl font-cub">
                            Thank you for your interest!
                        </h1>
                        <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl font-sof">
                            I'll be in touch soon.
                        </p>
                    </div>
                ) : (
                   <div className="mt-10">
                   <form action="#" className="sm:mx-auto sm:max-w-xl lg:mx-0 font-cub">
                     <div className="sm:flex">
                       <div className="min-w-0 flex-1">
                         <label htmlFor="email" className="sr-only">
                           Email address
                         </label>
                         <input
                           id="email"
                           type="email"
                           placeholder="Enter your email"
                           className="block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-300 focus:ring-offset-2 focus:ring-offset-gray-900"
                           onChange={(e) => setEmail(e.target.value)}
                         />
                       </div>
                       <div className="mt-3 sm:ml-3 sm:mt-0">
                         <button
                           type="submit"
                           className="block w-full rounded-md bg-sky-500 px-4 py-3 font-medium text-white shadow hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-300 focus:ring-offset-2 focus:ring-offset-gray-900"
                           onClick={handleEmail}
                         >
                             Leave your email
                         </button>
                       </div>
                     </div>
                     <p className="mt-3 text-sm text-gray-300 sm:mt-2 font-sof">
                         I'll never share your email with anyone else. This is just for me to contact you about future opportunities.
                       <a href="#" className="font-medium text-white">
                         {" "}
                         Privacy Policy
                       </a>
                       .
                     </p>
                   </form>
                 </div>
                )}
               
              </div>
            </div>
            <div className="-mb-16 mt-12 sm:-mb-48 lg:relative lg:m-0">
              <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0">
                {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                <img
                  className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none rounded-xl border border-sky-300 overflow-hidden "
                  src={bret}
                  alt="Bret Johnson"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* More main page content here... */}
    </main>
  );
}
