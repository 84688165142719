import { addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} = require("firebase/auth");
const { auth, db } = require("../firebase/firebase");

export const createFirebaseUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const docRef = await setDoc(doc(db, "users", user.uid), {
      email: user.email,
    });
    console.log(docRef);
    return docRef.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const loginFirebaseUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    console.log(user);
    return "logged in";
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const logout = async () => {
  try {
    await auth.signOut();
    return "logged out";
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const leaveEmail = async (email) => {
  try {
    const docRef = await setDoc(doc(db, "emails", email), {
      email: email,
    });
    console.log(docRef);
    return docRef.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const contactMessage = async (name, email, phone, message) => {
  try {
    const docRef = await setDoc(doc(db, "messages", email), {
      name: name,
      email: email,
      phone: phone,
      message: message,
    });
    console.log(docRef);
    return docRef.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createCollection = async (collectionName, collectionID) => {
  try {
    await setDoc(doc(db, "userCollections", collectionID), {
      name: collectionName,
      shared: [{
        message: "Welcome to your new collection",
      }]
    });
    return "success"
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCollection = async (collectionID) => {
  console.log(collectionID, "collectionID")
  try {
    const docSnap = await getDoc(doc(db, "userCollections", collectionID));
   
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      console.log("No such document!");
      return "No such document!";
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addMessageToCollection = async (collectionID, message) => {
  try {
    await updateDoc(doc(db, "userCollections", collectionID), {
      shared: arrayUnion({
        message: message,
      }),
    });
    return "success";
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteMessageFromCollection = async (collectionID, message) => {
  try {
    await updateDoc(doc(db, "userCollections", collectionID), {
      shared: arrayRemove({
        message: message,
      }),
    });
    return "success";
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createJoke = async (joke) => {
  try {
    const docRef = await setDoc(doc(db, "jokes", joke), {
      joke: joke,
    });
    console.log(docRef);
    return docRef.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateJoke = async (joke) => {
  try {
    const docRef = await setDoc(doc(db, "jokes", joke), {
      joke: joke,
    });
    console.log(docRef);
    return docRef.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteJoke = async (joke) => {
  try {
    const docRef = await setDoc(doc(db, "jokes", joke), {
      joke: joke,
    });
    console.log(docRef);
    return docRef.data();
  } catch (error) {
    console.log(error);
    return error;
  }
};


 
