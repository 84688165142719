import { FaArrowLeft } from 'react-icons/fa6'
import { logout } from '../firebasefunctions/functions';


export default function Dashboard() {

  const handleLogout = () => {
    logout();
};

  return (
    < div className='px-4 py-12'>
    <div className="text-sky-300 font-cub sm:text-6xl text-4xl text-center">
      Welcome to TechBret's Protected Route
    </div>
    <div className=" font-cub sm:text-4xl text-2xl text-center">
      This is a subscriber only area
    </div>

    <div className=" font-cub text-xl text-center mt-12">
      You can only see this page if you are logged in
    </div>
    
      
      <div className="mx-auto text-center mt-24">
          <button className="bg-yellow-300 hover:bg-sky-400 text-3xl text-zinc-950 font-cub py-2 px-4 rounded-full mt-12"
              onClick={handleLogout}
          >
              Logout
          </button>
      </div>
  </div>
  )
}
