import React, { useEffect, useState } from "react";
import { FaQuestion } from "react-icons/fa6";
import { Slider } from "../components/slider";

export default function PriceCalculator() {
  const selectedDate = new Date().toISOString().slice(0, 10);

  const [selectedDays, setSelectedDays] = useState([]);
  const [allDay, setAllDay] = useState(true);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [minHour, setMinHour] = useState("");
  const [maxHour, setMaxHour] = useState("");
  const [pricePerMonth, setPricePerMonth] = useState("0");
  const [ratePerHour, setRatePerHour] = useState("0");
  const [finalMaxMonth, setFinalMaxMonth] = useState(0);
  const [finalMinMonth, setFinalMinMonth] = useState(0);
  const [maxMonthValue, setMaxMonthValue] = useState(0);
  const [maxWeekValue, setMaxWeekValue] = useState(0);
  const [maxDayValue, setMaxDayValue] = useState(0);
  const [maxHourValue, setMaxHourValue] = useState(0);
  const [minMonthValue, setMinMonthValue] = useState(0);
  const [minWeekValue, setMinWeekValue] = useState(0);
  const [minDayValue, setMinDayValue] = useState(0);
  const [minHourValue, setMinHourValue] = useState(0);
  const [wifiCost, setWifiCost] = useState(0);
  const [perOfSpace, setPerOfSPace] = useState(0);
  const [ammenVals, setAmmenVals] = useState(0);
  const [updateHours, setUpdateHours] = useState(false);
  const [updateDays, setUpdateDays] = useState(false);
  const [updateWeeks, setUpdateWeeks] = useState(false);
  const [selectStart, setSelectStart] = useState("12:00 AM");
  const [selectEnd, setSelectEnd] = useState("12:00 AM");
  const [updateMonths, setUpdateMonths] = useState(false);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // This can be used to set the default values for the calculator
  const minMonth = 1833;
  const maxMonth = 3490;

  const [sliderValue, setSliderValue] = useState(50);

  useEffect(() => {
    // Format options for 24-hour clock
    const formatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Forces 24-hour clock
    };

    // Function to check if selectStart is one of the specific options
    const isSelectStartMidnight = [
      "12:00 AM",
      "12:15 AM",
      "12:30 AM",
      "12:45 AM",
    ].includes(selectStart);

    // Convert selectStart and selectEnd to military time format
    const startTime = isSelectStartMidnight
      ? "00:00"
      : new Date(`2023-01-01 ${selectStart}`).toLocaleTimeString(
          "en-US",
          formatOptions
        );
    const endTime = new Date(`2023-01-01 ${selectEnd}`).toLocaleTimeString(
      "en-US",
      formatOptions
    );

    setStartTime(startTime);
    setEndTime(endTime);
  }, [selectStart, selectEnd]);

  const generateHourArray = (startTime, endTime) => {
    const startDate = new Date(`2023-01-01 ${startTime}`);
    const endDate = new Date(`2023-01-01 ${endTime}`);
    const hourArray = [];

    while (startDate <= endDate) {
      const formattedTime = startDate.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      hourArray.push(formattedTime);
      startDate.setHours(startDate.getHours() + 1);
    }

    return hourArray;
  };

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  // Convert military time string to minutes past midnight
  function convertToMinutes(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  }

  // Calculate the time difference in hours
  function getTimeDifferenceInHours(startTime, endTime) {
    const startMinutes = convertToMinutes(startTime);
    const endMinutes = convertToMinutes(endTime);
    const timeDifferenceMinutes = endMinutes - startMinutes;
    return timeDifferenceMinutes / 60;
  }

  const calculateTotals = (max, min, price) => {
    const maxValue = parseInt(wifiCost) ? max + parseInt(wifiCost) : max;
    const percValue =
      parseInt(perOfSpace) > 1
        ? maxValue * ((parseInt(perOfSpace) + 5) / 100)
        : maxValue;
    const finalMax = ammenVals > 1 ? percValue + ammenVals * 35 : percValue;
    setFinalMaxMonth(finalMax.toFixed(2));

    const minMonthlyAvg =
      parseInt(pricePerMonth) < 1 ? (parseInt(min) + parseInt(price)) / 2 : min;
    const minValue = parseInt(wifiCost)
      ? minMonthlyAvg + parseInt(wifiCost)
      : minMonthlyAvg;
    const percMinValue =
      parseInt(perOfSpace) > 1
        ? minValue * ((parseInt(perOfSpace) + 5) / 100)
        : minValue;
    const finalMin =
      ammenVals > 1 ? percMinValue + ammenVals * 35 : percMinValue;

    setFinalMinMonth(finalMin.toFixed(2));

    const mxm = finalMax + sliderValue * 4;
    const mxw = finalMax / 4 + sliderValue * 3 + ammenVals * 15;
    const mxd = finalMax / 28 + sliderValue * 2 + ammenVals * 20;
    const mxh = ammenVals
      ? finalMax / 4 / 7 / 24 + sliderValue * 1 + ammenVals * 25
      : finalMax / 4 / 7 / 24 + sliderValue * 1;

    const mnm = finalMin + sliderValue * 4;
    const mnw = finalMin / 4 + sliderValue * 3 + ammenVals * 15;
    const mnd = finalMin / 4 / 7 + sliderValue * 2 + ammenVals * 20;
    const mnh = ammenVals
      ? finalMin / 4 / 7 / 24 + sliderValue * 1 + ammenVals * 25
      : finalMin / 4 / 7 / 24 + sliderValue * 1;

    setMaxMonthValue(mxm);
    setMaxWeekValue(mxw);
    setMaxDayValue(mxd);
    setMaxHourValue(mxh);
    setMinMonthValue(mnm);
    setMinWeekValue(mnw);
    setMinDayValue(mnd);
    setMinHourValue(mnh);
    setMinHour(mnh.toFixed(2));
    setMaxHour(mxh.toFixed(2));
  };

  useEffect(() => {
    if (selectedDays?.length === 0) {
      setMaxMonthValue(0);
      setMaxWeekValue(0);
      setMaxDayValue(0);
      setMaxHourValue(0);
      setMinMonthValue(0);
      setMinWeekValue(0);
      setMinDayValue(0);
      setMinHourValue(0);
    } else if (allDay) {
      const totalDays = selectedDays.length * 4;
      const ttDays8 =
        parseInt(pricePerMonth) > maxMonth
          ? totalDays *
            (parseInt(pricePerMonth) - maxMonth + parseInt(pricePerMonth))
          : totalDays * maxMonth;
      const ttDay =
        parseInt(pricePerMonth) > maxMonth
          ? totalDays * parseInt(pricePerMonth)
          : totalDays * minMonth;

      const costPerDaysPerMonth = ttDays8 / 28;

      const costPerDaysPerMonthMin = ttDay / 28;

      calculateTotals(
        costPerDaysPerMonth,
        costPerDaysPerMonthMin,
        pricePerMonth
      );
    } else {
      const totalDays = selectedDays.length * 4;
      const timeDifferenceHours = getTimeDifferenceInHours(startTime, endTime);
      const totalHoursForSelected = totalDays * timeDifferenceHours;

      const calCalculateMaxChargeFromHoursSelected =
        parseInt(pricePerMonth) > maxMonth
          ? (totalHoursForSelected *
              (parseInt(pricePerMonth) - maxMonth + parseInt(pricePerMonth))) /
            672
          : (totalHoursForSelected * maxMonth) / 672;

      const calCalculateMinChargeFromHoursSelected =
        parseInt(pricePerMonth) > maxMonth
          ? (totalHoursForSelected * parseInt(pricePerMonth)) / 672
          : (totalHoursForSelected * minMonth) / 672;

      const costPerHourForMortage = parseInt(pricePerMonth) / 672;
      const price = totalHoursForSelected * costPerHourForMortage;

      const maxMonthlyAvg =
        parseInt(pricePerMonth) > 1
          ? (parseInt(calCalculateMaxChargeFromHoursSelected) +
              parseInt(price)) /
            2
          : calCalculateMaxChargeFromHoursSelected;

      calculateTotals(
        maxMonthlyAvg,
        calCalculateMinChargeFromHoursSelected,
        price
      );
    }
  }, [
    allDay,
    selectedDays,
    sliderValue,
    maxMonth,
    minMonth,
    minHour,
    pricePerMonth,
    wifiCost,
    perOfSpace,
    ammenVals,
    endTime,
    startTime,
  ]);

  const generateTimeOptions = () => {
    const options = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = convertTo12HourFormat(hour, minute);
        options.push(
          <option value={time} key={time}>
            {time}
          </option>
        );
      }
    }

    return options;
  };
  const convertTo12HourFormat = (hour, minute) => {
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    const formattedMinute = padZero(minute);

    return `${formattedHour}:${formattedMinute} ${period}`;
  };
  const padZero = (number) => {
    return number.toString().padStart(2, "0");
  };

  const calculateTotalHours = (startTime, endTime) => {
    const startHour = parseInt(startTime.split(":")[0], 10);
    const startMinute = parseInt(startTime.split(":")[1], 10);

    const endHour = parseInt(endTime.split(":")[0], 10);
    const endMinute = parseInt(endTime.split(":")[1], 10);

    let currentHour = startHour;
    let currentMinute = startMinute;
    let hours = [];

    while (
      currentHour < endHour ||
      (currentHour === endHour && currentMinute <= endMinute)
    ) {
      hours.push(
        `${currentHour.toString().padStart(2, "0")}:${currentMinute
          .toString()
          .padStart(2, "0")}`
      );
      currentHour++;

      if (currentHour === endHour && currentMinute < endMinute) {
        currentMinute = endMinute;
      } else {
        currentMinute = 0;
      }
    }

    return hours;
  };

  const [days, setDays] = useState(() => {
    return daysOfWeek.map((day, index) => ({
      name: day,
      value: day,
      id: index,
      checked: false,
      hours: generateHourArray(selectStart, selectEnd),
      acceptsPricePerHour: true,
      minPricePerHour: 0,
      maxPricePerHour: 0,
      minPricePerDay: 0,
      maxPricePerDay: 0,
    }));
  });

  useEffect(() => {
    const updatedDays = days.map((d) => ({
      ...d,
      checked: selectedDays.includes(d.value),
      minPricePerHour: parseInt(minHourValue).toFixed(2),
      maxPricePerHour: parseInt(maxHourValue).toFixed(2),
      minPricePerDay: parseInt(minDayValue).toFixed(2),
      maxPricePerDay: parseInt(maxDayValue).toFixed(2),
    }));
    setDays(updatedDays);
  }, [selectedDays, minHourValue, maxHourValue, minDayValue, maxDayValue]);

  useEffect(() => {
    setDays((prevDays) => {
      const updatedDays = prevDays.map((d) => ({
        ...d,
        hours:
          !allDay && selectedDays.includes(d.value)
            ? calculateTotalHours(startTime, endTime)
            : [],
      }));
      return updatedDays;
    });
  }, [startTime, endTime, selectedDays, allDay, ratePerHour]);

  return (
    <div className="grid sm:grid-cols-2 gap-4 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2">
      <div>
        <h1 className="text-4xl  font-cub">Price Calculator</h1>
        <p className="font-sof text-gray-100 ">
          This is a sample price calculator that can be used to calculate the
          price of a space based on the time of day, day of the week, and other
          factors.
        </p>
        <h3 className="text-2xl font-cub mt-12">Why is this unique?</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          I developed this pricing calculator to help clients shift into the
          world of dynamic pricing models. It harnesses cutting-edge algorithms
          and data-driven methodologies to dynamically compute the cost of
          items, whether they be rental properties or products, by factoring in
          a multitude of variables input by the user. My calculator operates on
          a sophisticated foundation of machine learning and predictive
          analytics, enabling it to adapt and optimize pricing in real-time,
          providing a granular and accurate assessment of value. Unlike static
          calculators, my solution evolves with the changing landscape of a
          business, ensuring that your pricing strategy remains agile and
          competitive. It's not just a calculator; it's a dynamic pricing engine
          that can revolutionize your pricing strategies and enhance your bottom
          line
        </p>
        <h3 className="text-2xl font-cub mt-12">This Example:</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          Imagine you're a property owner or manager looking to optimize your
          rental income. My pricing calculator takes the guesswork out of
          determining potential revenue by leveraging a robust blend of
          data-driven mechanisms.
        </p>
        <p className="font-sof text-lg text-gray-100 text-left mt-4">
          The magic happens as the calculator dynamically adjusts the rental
          price based on complex analysis. It continuously updates its
          estimation when leveraging your real estate API, or manualy adjusting
          as needed. The calculator is then taking into account real-time market
          data, competitor pricing, and user-defined constraints, such as
          desired profit margins or booking frequency.
        </p>
        <p className="font-sof text-lg text-gray-100 text-left mt-4">
          This iterative process results in a highly accurate and responsive
          revenue projection. As you modify the input parameters, my calculator
          instantly recalibrates, providing a real-time estimate of your rental
          property's potential earnings. It's like having a dedicated data
          scientist at your fingertips, optimizing your pricing strategy 24/7 to
          maximize your revenue potential.
        </p>
      </div>
      <div>
        <div className="font-cub text-zinc-600">
          <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl rounded-md">
            <div className="flex min-h-0 flex-1 flex-col py-6">
              <div className="relative flex-1 px-4 sm:px-6">
                <div className="font-medium leading-6 text-gray-900 mb-1">
                  What days will this be available to rent?{" "}
                  <span className="text-xs">(Select all that apply)</span>
                </div>
                <div className="grid sm:grid-cols-4 grid-cols-2 gap-1 mt-4">
                  {daysOfWeek?.map((day) => (
                    <button
                      key={day}
                      className={`border p-1 rounded-md ${
                        selectedDays.includes(day)
                          ? "bg-gray-900 text-white text-sm h-12"
                          : "bg-gray-100 text-gray-900 text-sm h-12"
                      }`}
                      onClick={() => {
                        if (selectedDays.includes(day)) {
                          setSelectedDays(
                            selectedDays.filter((d) => d !== day)
                          );
                        } else {
                          setSelectedDays([...selectedDays, day]);
                        }
                      }}
                    >
                      {day}
                    </button>
                  ))}
                  <button
                    className="border p-1 rounded-md bg-gray-100 text-gray-900 text-sm h-12"
                    onClick={() => {
                      if (selectedDays.length === daysOfWeek.length) {
                        setSelectedDays([]);
                      } else {
                        setSelectedDays([...daysOfWeek]);
                      }
                    }}
                  >
                    {selectedDays.length === daysOfWeek.length
                      ? "Clear All"
                      : "All Days"}
                  </button>
                </div>
                {allDay ? (
                  <div className="grid grid-cols-4 gap-1 mt-6">
                    <div className="flex items-center col-span-4 font-medium leading-6 text-gray-900 mb-1">
                      Will you allow a renter to rent for 24 hours on selected
                      days?
                      <span className="ml-2 relative group pr-40 md:pr-0">
                        <FaQuestion className="h-4 w-4 text-gray-500" />
                        <div className="absolute z-10 bg-white w-44 shadow-md px-2 py-1 text-xs text-gray-600 rounded-lg scale-0 group-hover:scale-100">
                          Renters will be able to rent your space for 24 hours
                          on the days you selected. If you want to allow users
                          to rent your space for specific time slots, select
                          "Specific Time Slots" below.
                        </div>
                      </span>
                    </div>
                    <button className="rounded-md focus:bg-white py-1.5 px-2.5 text-sm font-semibold focus:text-stone-700 border border-1 border-stone-700 shadow-sm hover:bg-gray-900 bg-stone-700 text-white col-span-2 h-12">
                      Yes - 24 Hours
                    </button>
                    <button
                      className="rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-stone-700 border border-1 border-stone-700 shadow-sm hover:bg-indigo-50 focus:bg-stone-700 focus:text-white col-span-2 h-12"
                      onClick={() => {
                        setAllDay(false);
                      }}
                    >
                      No - Select Hours
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-2 gap-1 mt-6">
                      <div className="flex items-center col-span-2 font-medium leading-6 text-gray-900 mb-1">
                        Will you allow a renter to book for 24 hours on selected
                        days?
                        <span className="ml-2 relative group pr-40 md:pr-0">
                          <FaQuestion className="h-4 w-4 text-gray-500" />
                          <div className="absolute z-10 bg-white w-44 shadow-md px-2 py-1 text-xs text-gray-600 rounded-lg scale-0 group-hover:scale-100">
                            Renters will be able to rent your space for 24 hours
                            on the days you selected. If you want to allow users
                            to rent your space for specific time slots, select
                            "Specific Time Slots" below.
                          </div>
                        </span>
                      </div>
                      <button
                        className="rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-stone-700 border border-1 border-stone-700 shadow-sm hover:bg-indigo-50 focus:bg-stone-700 focus:text-white h-12"
                        onClick={() => {
                          setAllDay(true);
                          setStartTime("00:00");
                          setEndTime("23:59");
                        }}
                      >
                        Yes - 24 Hours
                      </button>
                      <button className="rounded-md focus:bg-white py-1.5 px-2.5 text-sm font-semibold focus:text-stone-700 border border-1 border-stone-700 shadow-sm hover:bg-gray-900 bg-stone-700 text-white h-12">
                        No - Select Hours
                      </button>
                    </div>
                    <div className="grid grid-cols-3 gap-1 mt-4 border border-1 p-4 rounded-md bg-gray-100">
                      <div className="flex items-center col-span-2 block text-sm font-medium leading-6 text-gray-900">
                        Start Time
                        <span className="ml-2 relative group">
                          <FaQuestion className="h-4 w-4 text-gray-500" />
                          <div className="absolute z-10 bg-white w-44 shadow-md px-2 py-1 text-xs text-gray-600 rounded-lg scale-0 group-hover:scale-100">
                            Time slots are broken up into days and times. You
                            can choose the start and finish times for each block
                            of time that your space is available.
                          </div>
                        </span>
                      </div>
                      <div className="relative rounded-md shadow-sm">
                        <select
                          name="start"
                          id="start"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-lg sm:leading-6 text-right h-12"
                          onChange={(e) => {
                            setSelectStart(e.target.value);
                          }}
                        >
                          {generateTimeOptions()}
                        </select>
                      </div>
                      <div className="flex items-center col-span-2 block text-sm font-medium leading-6 text-gray-900">
                        End Time
                        <span className="ml-2 relative group">
                          <FaQuestion className="h-4 w-4 text-gray-500" />
                          <div className="absolute z-10 bg-white w-44 shadow-md px-2 py-1 text-xs text-gray-600 rounded-lg scale-0 group-hover:scale-100">
                            Time slots are broken up into days and times. You
                            can choose the start and finish times for each block
                            of time that your space is available.
                          </div>
                        </span>
                      </div>
                      <div className="relative rounded-md shadow-sm">
                        <select
                          name="end"
                          id="end"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-lg h-12 sm:leading-6 text-right"
                          onChange={(e) => {
                            setSelectEnd(e.target.value);
                          }}
                        >
                          {generateTimeOptions()}
                        </select>
                      </div>
                    </div>
                  </>
                )}
                <div className="grid sm:grid-cols-4 grid-cols-1 gap-1 mt-6 border border-1 p-4 rounded-md bg-gray-100">
                  <div className="sm:col-span-4 font-bold tracking-tight text-zinc-900">
                    Location Information
                  </div>
                  <div className="mt-2 flex items-center sm:col-span-3 text-sm font-medium leading-6 text-gray-900">
                    Current Mortgage/Rent?
                    <span className="ml-2 relative group">
                      <FaQuestion className="h-4 w-4 text-gray-500" />
                      <div className="absolute z-10 bg-white w-44 shadow-md px-2 py-1 text-xs text-gray-600 rounded-lg scale-0 group-hover:scale-100">
                        This is how much you pay to maintain your overall
                        Location, not just this space. Please enter the amount
                        you pay per month.
                      </div>
                    </span>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-lg mt-2">$</span>
                    </div>
                    <input
                      type="text"
                      name="maxMonth"
                      id="maxMonth"
                      className="block mt-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-lg sm:leading-6 text-right h-12"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                      onChange={(e) => {
                        setPricePerMonth(e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex items-center sm:col-span-3 text-sm font-medium leading-6 text-gray-900">
                    Cost of Utilities/Bills per month?
                    <span className="ml-2 relative group">
                      <FaQuestion className="h-4 w-4 text-gray-500" />
                      <div className="absolute z-10 bg-white w-44 shadow-md px-2 py-1 text-xs text-gray-600 rounded-lg scale-0 group-hover:scale-100">
                        This is how much you pay to maintain your overall
                        Location, not just this space. Please enter the amount
                        you pay per month.
                      </div>
                    </span>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-lg">$</span>
                    </div>
                    <input
                      type="text"
                      name="maxMonth"
                      id="maxMonth"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-lg sm:leading-6 text-right h-12"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                      onChange={(e) => {
                        setWifiCost(e.target.value);
                      }}
                    />
                  </div>
                  <div className=" flex items-center sm:col-span-3 block text-sm font-medium leading-6 text-gray-900">
                    What percentage of square footage does this space represent?
                    <span className="ml-2 relative group">
                      <FaQuestion className="h-4 w-4 text-gray-500" />
                      <div className="absolute z-10 bg-white w-44 shadow-md px-2 py-1 text-xs text-gray-600 rounded-lg scale-0 group-hover:scale-100">
                        This is how much area this space takes up in your
                        overall Location. Please enter the percentage of your
                        Location that this space takes up. Estimates are fine.
                      </div>
                    </span>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5  pr-8 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-lg sm:leading-6 h-12"
                      placeholder="0"
                      aria-describedby="price-currency"
                      onChange={(e) => {
                        setPerOfSPace(e.target.value);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-3">
                      <span
                        className="text-gray-500 sm:text-lg"
                        id="price-currency"
                      >
                        %
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center sm:col-span-3 text-sm font-medium leading-6 text-gray-900">
                    Number of amenities included to renter valued over $1000?
                    (Optional)
                  </div>
                  <div className="relative rounded-md mr-auto">
                    <input
                      type="number"
                      name="maxMonth"
                      id="maxMonth"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-lg sm:leading-6 text-center h-12"
                      placeholder="0"
                      aria-describedby="price-currency"
                      onChange={(e) => {
                        setAmmenVals(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-6 border grid grid-cols-4 border-1 p-4 rounded-md bg-gray-100 flex inline items-center">
                  <div className="flex items-center col-span-4 block text-sm font-medium leading-6 text-gray-900">
                    Using the scale below what is most important to you?
                  </div>
                  <div className="mt-4 text-xs text-left">Community Impact</div>
                  <div></div>
                  <div></div>
                  <div className="mt-4 text-xs text-right">
                    Increased Revenue
                  </div>
                  <div className="mt-4 col-span-4">
                    <Slider
                      defaultValue={[sliderValue]}
                      onValueChange={(e) => {
                        setSliderValue(e);
                      }}
                      max={100}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="text-xl mt-6">
                  <div className="flex items-center">
                    <span className="font-medium">Estimated Hourly: </span>
                    {updateHours ? (
                      <input
                        type="text"
                        className="ml-2 mr-2 block w-1/4 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-sm sm:leading-6 text-right"
                        placeholder="0.00"
                        onChange={(e) => {
                          setMinHourValue(parseInt(e.target.value));
                          setMaxHourValue(parseInt(e.target.value));
                        }}
                      />
                    ) : (
                      <span className="ml-2 mr-2 text-blue-800 font-bold text-2xl ">
                        ${minHourValue.toFixed(0)} - ${maxHourValue.toFixed(0)}
                      </span>
                    )}
                    /per hour
                  </div>
                  <div className="flex items-center mt-2">
                    <span className="font-medium">Estimated Daily: </span>
                    {updateDays ? (
                      <input
                        type="text"
                        className="ml-2 mr-2 block w-1/4 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-sm sm:leading-6 text-right"
                        placeholder="0.00"
                        onChange={(e) => {
                          setMinDayValue(parseInt(e.target.value));
                          setMaxDayValue(parseInt(e.target.value));
                        }}
                      />
                    ) : (
                      <span className="ml-2 mr-2 text-blue-800 font-bold text-2xl ">
                        ${minDayValue.toFixed(0)} - ${maxDayValue.toFixed(0)}
                      </span>
                    )}
                    /per day
                  </div>
                  <div className="flex items-center mt-2">
                    <span className="font-medium">Estimated Weekly: </span>
                    {updateWeeks ? (
                      <input
                        type="text"
                        className="ml-2 mr-2 block w-1/4 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-sm sm:leading-6 text-right"
                        placeholder="0.00"
                        onChange={(e) => {
                          setMinWeekValue(parseInt(e.target.value));
                          setMaxWeekValue(parseInt(e.target.value));
                        }}
                      />
                    ) : (
                      <span className="ml-2 mr-2 text-blue-800 font-bold text-2xl ">
                        ${minWeekValue.toFixed(0)} - ${maxWeekValue.toFixed(0)}
                      </span>
                    )}
                    /per week
                  </div>
                  <div className="flex items-center mt-2">
                    <span className="font-medium">Estimated Monthly:</span>
                    {updateMonths ? (
                      <input
                        type="text"
                        className="ml-2 mr-2 block w-1/4 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-stone-700 sm:text-sm sm:leading-6 text-right"
                        placeholder="0.00"
                        onChange={(e) => {
                          setMinMonthValue(parseInt(e.target.value));
                          setMaxMonthValue(parseInt(e.target.value));
                        }}
                      />
                    ) : (
                      <span className="ml-2 mr-2 text-blue-800 font-bold text-2xl ">
                        ${minMonthValue.toFixed(0)} - $
                        {maxMonthValue.toFixed(0)}
                      </span>
                    )}
                    /per month
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 justify-end items-center px-4 py-8 bg-gray-900">
              <div className="mr-auto text-2xl font-medium text-white">
                Estimated Earnings:
              </div>
              <div className="ml-auto text-3xl font-bold text-sky-400">
                ${minMonthValue.toFixed(0)} - ${maxMonthValue.toFixed(0)}
                <span className="text-lg font-normal text-white ml1">
                  /per month
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
