import { Disclosure } from "@headlessui/react";
import BretsResume from "../assets/Bret_Resume_Updated_1223_S.pdf";
import {
  FaBars,
  FaDownload,
  FaMessage,
  FaXmark,
} from "react-icons/fa6";
import logo from "../assets/logos/mainLogo.png";


const navigation = [
  { name: "Portfolio", href: "port", current: false },
  // { name: "Code Samples", href: "codesamples", current: false },
  // { name: "References", href: "#", current: false },
  { name: "Resume", href: "resume", current: false },
  { name: "Contact", href: "contact", current: false },
  // { name: "About Bret", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const scrollToPortfolio = (page) => {
    const portfolioSection = document.getElementById(page);
    if (portfolioSection) {
      portfolioSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Disclosure as="nav" className="bg-zinc-950 sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 py-4 font-cub">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <FaXmark className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <FaBars className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <img className="h-24 w-auto" src={logo} alt="TechBret" />
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {navigation.map((item) => (
                    <button
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? "" : "",
                        "font-display text-base font-normal text-white px-2 py-1 hover:drop-shadow-[0_0_12px_rgba(253,56,252,1)] hover:scale-105 transition-transform"
                      )}
                      aria-current={item.current ? "page" : undefined}
                      onClick={() => scrollToPortfolio(item.href)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <a
                    href={BretsResume}
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-700 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 tracking-widest"
                    download={BretsResume}
                  >
                    <FaDownload
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    Resume
                  </a>
                </div>
                <div className="flex-shrink-0 ml-2 hidden sm:block">
                  <button
                    type="button"
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-sky-500 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 tracking-widest"
                    onClick={() => scrollToPortfolio("contact")}
                  >
                    <FaMessage className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Hire Me
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                  onClick={() => scrollToPortfolio(item.href)}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
