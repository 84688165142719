import icon from "../assets/logos/icon.png";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useState } from "react";
import { loginFirebaseUser } from "../firebasefunctions/functions";
export default function Login() {
  const { setIsLoading } = UserAuth();
  const [error, setError] = useState(null); // [error, setError
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await loginFirebaseUser(email, password).then((err) => {
      console.log(err);
      if (err) {
        setError(err);
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      <div className="">
        <img className="mx-auto h-36 w-auto" src={icon} alt="Code Tega" />
        <h2 className="text-center text-2xl  leading-9 tracking-wide text-white">
          Sign into your <span className="text-sky-400">TechBret</span> Account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12 ${
                  error && "ring-2 ring-red-500"
                }`}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-white"
              >
                Password
              </label>
              <div className="text-sm">
                <a
                  href="#"
                  className="font-semibold text-sky-400 hover:text-sky-300"
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={`block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12 ${
                  error && "ring-2 ring-red-500"
                } `}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-sky-500 px-3 py-3 text-2xl font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500 h-12"
              onClick={handleLogin}
            >
              Sign in
            </button>
            {error && (
              <p className="mt-2 text-center text-sm text-red-500">
                {error.message}
              </p>
            )}
          </div>
        </div>

        <p className="mt-10 text-center text-sm text-gray-400">
          No Account?{" "}
          <Link
            to="/create-user"
            href="https://calendly.com/codetega/coaching-consultation"
            className="font-semibold leading-6 text-sky-400 hover:text-sky-300"
          >
            Create one
          </Link>
        </p>
      </div>
    </div>
  );
}
