import React, { useEffect, useRef, useState } from "react";
import {
  addMessageToCollection,
  createCollection,
  deleteMessageFromCollection,
  getCollection,
} from "../firebasefunctions/functions";
import Loading from "../components/Loading";
import { FaCopy } from "react-icons/fa6";
import toast, { Toaster } from "react-hot-toast";

export default function DatabaseOperations() {
  const [collectionName, setCollectionName] = useState("");
  const [collection, setCollection] = useState([]);
  const [collectionID, setCollectionID] = useState("");
  const [manualCollectionID, setManualCollectionID] = useState("");
  const [collectionCreated, setCollectionCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const inputRef = useRef(null);

  const notify = () => toast.success("Copied to clipboard!");

  const handleGetCollection = (e) => {
    e.preventDefault();
    
    setCollectionID(manualCollectionID);
    getCollection(manualCollectionID).then((result) => {
      if (result === "No such document!") {
        console.log(result);
      } else {
        const data = result.shared;
        setCollection(data);
        setCollectionCreated(true);
        setManualCollectionID("");
      }
    });
  };

  const handleCreateCollection = () => {
    setLoading(true);
    // Generate a random number between 100000 and 999999 (inclusive)
    const collectMath = Math.floor(100000 + Math.random() * 900000).toString();
    const collectionID = collectionName[0] + collectMath;
    setCollectionID(collectionID);
    createCollection(collectionName, collectionID).then((result) => {
      if (result === "success") {
        setCollectionCreated(true);
        setLoading(false);
      } else {
        console.log(result);
        setLoading(false);
      }
    });
  };

  const handleAddMessage = () => {
    setCollection([...collection, { message: message }]);
    addMessageToCollection(collectionID, message).then((result) => {
      if (result === "success") {
        console.log(result);
        inputRef.current.value = "";
      } else {
        console.log(result);
      }
    });
    setMessage("");
  };

  const handleDeleteMessage = (e, message) => {
    e.preventDefault();
    setCollection(collection.filter((item) => item.message !== message));
    deleteMessageFromCollection(collectionID, message).then((result) => {
      if (result === "success") {
        console.log(result);
      } else {
        console.log(result);
      }
    });
  };

  useEffect(() => {
    if (collectionID) {
      getCollection(collectionID).then((result) => {
        if (result === "No such document!") {
          console.log(result);
        } else {
          const data = result.shared;
          setCollection(data);
          
        }
      });
    }
  }, [collectionID]);

  const handleCopy = () => {
    navigator.clipboard.writeText(collectionID);
    notify();
  };

  return (
    <div className="grid sm:grid-cols-4 gap-4 gap-y-10 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2 mt-36 ">
      {loading ? (
        <div className="sm:col-span-2">
          <Loading />
        </div>
      ) : collectionCreated ? (
        <div className="sm:col-span-2 text-center sm:max-w-md mx-auto">
          <div className="text-4xl font-cub">
            {" "}
            <span className="text-sky-400 underline">{collectionName}</span> has
            been created
          </div>
          <Toaster />
          <div className="text-xl font-cub mt-8">
            Your Collection ID is{" "}
            <button onClick={handleCopy}>
              <span className="text-sky-400">
                {collectionID}{" "}
                <FaCopy className="inline-block ml-2 text-sky-400" />
              </span>
            </button>
          </div>
          <p className="font-sof text-xs text-gray-100 ">
            You can share this ID with your friends so they can add to your
            collection.
          </p>
          <div className="mt-2 flex">
            <input
            ref={inputRef}
              id="doc"
              name="doc"
              type="text"
              autoComplete="doc"
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm font-cub ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12"
              placeholder="Share something with your friends"
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              className="ml-2 px-4 py-2 bg-sky-500 hover:bg-sky-700 rounded-md text-white font-cub"
              onClick={handleAddMessage}
            >
              Add
            </button>
          </div>
          <tbody className="divide-y divide-gray-800 text-left font-cub">
            {collection?.map((item) => (
              <tr key={item}>
                <td className="sm:px-6 py-4 whitespace-nowrap">
                  <div className="text-sm truncate max-w-52">
                    {item.message}
                  </div>
                </td>
                <td className="sm:px-6 py-4 whitespace-nowrap text-sm text-red-500">
                  <button onClick={(e) => handleDeleteMessage(e, item.message)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </div>
      ) : (
        <div className="sm:col-span-2 font-cub sm:px-12 px-4">
          <h3 className="text-2xl font-cub  text-sky-400">
            Create your Collection
          </h3>
          <div className="mt-2 flex">
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12"
              placeholder="Collection Name"
              onChange={(e) => setCollectionName(e.target.value)}
            />
            <button
              className="ml-2 px-4 py-2 bg-sky-500 hover:bg-sky-700 rounded-md text-white font-cub"
              onClick={handleCreateCollection}
            >
              Create
            </button>
          </div>
          <div className="mt-4">or</div>
          <h3 className="text-2xl font-cub mt-4 text-sky-400">
            Find your Collection
          </h3>
          <div className="mt-2 flex">
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 h-12"
              placeholder="Your Collection ID"
              onChange={(e) => setManualCollectionID(e.target.value)}
            />
            <button
              className="ml-2 px-4 py-2 bg-sky-500 hover:bg-sky-700 rounded-md text-white font-cub"
              onClick={(e) => handleGetCollection(e)}
            >
              Find
            </button>
          </div>
        </div>
      )}

      <div className="col-span-2">
        <h1 className="text-4xl  font-cub">Database Operations</h1>
        <p className="font-sof text-gray-100 text-lg">
          This is a simple database operation demonstration that allows you to
          create a collection and share it with your friends. You can also take
          a collection that your friends have created and add to it.
        </p>
        <h3 className="text-2xl font-cub mt-12">How does it work?</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          In this example, I use Firestore to create a collection of items.
          Firestore is an amazing database that allows you to create collections
          and documents similiar to how SQL allows you to create tables and
          columns. In this demonstration you can create a collection and give it
          any title you want, then you can add items to that collection which
          are stored as documents.
        </p>
        <h3 className="text-2xl font-cub mt-12 text-yellow-300">
          How do I leverage Firebase Read/Write to minimize cost for clients?
        </h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          React is one of the best tools out there for managing data from a
          Backend as a Service (BaaS) like Firebase. However, it can be
          expensive to read and write data from a BaaS. To minimize cost for
          clients, I leverage the React Context hook to store data locally in
          the browser.
        </p>
        <p className="font-sof text-lg text-gray-100 text-left mt-2">
          This is done on login or initializing the state. I call a UseEffect
          hook that runs any function I have to call data from firebase and
          store it in global variables that are shared throughout the site. This
          way, the client only has to read from the database once and can then
          navigate through the site without having to make constanct calls to
          the database.
        </p>
      </div>
    </div>
  );
}
