import React from 'react'

export default function VideoRecorder() {
  return (
    <div className="grid sm:grid-cols-4 gap-4 gap-y-10 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2 mt-36 items-center">
      
      <div className="col-span-2">
        <h1 className="text-4xl  font-cub">Video Recorder</h1>
        <p className="font-sof text-gray-100 text-lg">
          This is a simple video recorder that uses the webcam on your computer to record a video. You can then download the video to your computer. This is a great way to record a video for a job interview or to send a video message to a friend.
        </p>
        <h3 className="text-2xl font-cub mt-12">How does it work?</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          I use a library called <a className="text-blue-500" href="https://www.npmjs.com/package/react-webcam">react-webcam</a> to capture the video. This library allows you to save the video as a blob. I then also save the video as a base64 string in the database. When the video is recorded, this page will capture the users IP address and save it in the database. This way, if there is ever a dispute, you can verify that the video was recorded from the same IP address that the video was sent to.
        </p>
      </div>
      <div className="col-span-2 -mt-24 font-cub"></div>
    </div>
  )
}
