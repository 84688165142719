import React from "react";
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import Portfolio from "./Portfolio";
import Resume from "./Resume";
import Footer from "../components/Footer";
import Contact from "./Contact";

export default function Home() {
  return (
    <div>
      <NavBar />
      <Hero />
      <Portfolio />
      <Resume />
      <Contact />
      <Footer />
    </div>
  );
}
