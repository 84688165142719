import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";

export default function Contracts() {
  const [accepted, setAccepted] = useState([]);
  const [user, setUser] = useState(null);
  const [isRenter, setIsRenter] = useState(false);
  const [ip, setIp] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [checkbox, setCheckbox] = useState(false);
  const [contract, setContract] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [providerSigns, setProviderSigns] = useState(false);
  const [tenantSigns, setTenantSigns] = useState(false);
  const [rentSigSaved, setRentSigSaved] = useState(false);
  const [sigSaved, setSigSaved] = useState(false);
  const [signature, setSignature] = useState({});
  const signPad = useRef(null);
  const [providerTyped, setProviderTyped] = useState("");
  const [tenantTyped, setTenantTyped] = useState("");

  const navigate = useNavigate();

  const handleClear = () => {
    setSignature({});
    signPad.current.clear();
  };

  const handleSave = () => {
    const data = signPad.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(data);
    setSigSaved(true);
    setProviderSigns(false);
  };

  const handleRenterSave = () => {
    const data = signPad.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(data);
    setRentSigSaved(true);
    setTenantSigns(false);
  };

  const { id } = useParams();

  useEffect(() => {
    if (accepted[0]) {
      const contract = accepted.find((contract) => contract.id === id);
      setContract(contract);
      setStart(contract?.licenseStart);
      setEnd(contract?.licenseEnd);
    }
  }, [accepted, id]);

  const handleGetIP = async () => {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    const timestamp = new Date();
    setIp(data.ip);
    setTimestamp(timestamp);
    setCheckbox(!checkbox);
    console.log(data.ip);
  };

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  function formatDate(date) {
    const bookedDate = new Date(date);
    const newDate = addDays(bookedDate, 1); // Adding 1 day to the original date
    return dateFormat(newDate, "ddd, mmm dS, yyyy");
  }


  return (
    <div className="grid sm:grid-cols-6 gap-4 gap-y-10 grid-cols-1 max-w-full mx-auto sm:px-8 px-2 mt-24">
    <div className="col-span-2">
      <h1 className="text-4xl  font-cub">Contracts</h1>
      <p className="font-sof text-gray-100 text-lg">
        Sometimes you need to have a contract signed, and you want to avoide the hassle of printing, signing, and scanning. This example allows you to upload a contract, sign it, and send it to the other party. The other party can then sign it and send it back to you. This is a great way to get contracts signed quickly and easily without having pay for a service.
      </p>
      <h3 className="text-2xl font-cub mt-12">How do you ensure the signature is valid?</h3>
      <p className="font-sof text-lg text-gray-100 text-left">
        I use a library called <a className="text-blue-500" href="https://www.npmjs.com/package/react-signature-canvas">react-signature-canvas</a> to capture the signature. This library allows you to save the signature as an image. I then also save the signature as a base64 string in the database. When the contract is signed, this page will capture the users IP address and save it in the database. This way, if there is ever a dispute, you can verify that the signature was signed from the same IP address that the contract was sent to.
      </p>
    </div>
    <div className="col-span-4">
    <div>
      <div className="overflow-hidden bg-white px-6 py-16 lg:px-8 rounded-md">
        <div className="mx-auto max-w-max lg:max-w-7xl">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="max-w-prose text-base lg:max-w-none">
              <h2 className="font-semibold leading-6 text-indigo-600">
                TechBret
              </h2>
              <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                License Agreement Example
              </p>
            </div>
          </div>
          <div className="relative">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-6 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold">Person 1</h3>
                <p>
                  Full Name:{" "}
                  <span className="font-bold">
                    Joe Smith
                  </span>
                </p>
                <p>
                  Address:{" "}
                  <span className="font-bold capitalize">
                   123 Main St. Anytown, USA 12345
                  </span>
                </p>
                <p>
                  Phone Number:{" "}
                  <span className="font-bold">555-555-5555</span>
                </p>
                <p>
                  Email:{" "}
                  <span className="font-bold">anyemail@email.com</span>
                </p>
              </div>
              <div className="col-span-6 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold">Person 2</h3>
                <p>
                  Renter Name:{" "}
                  <span className="font-bold">
                    Jamie Jones
                  </span>
                </p>
                <p>
                  Address:{" "}
                  <span className="font-bold">
                    321 3rd St. Anytown, USA 12345
                  </span>
                </p>
                <p>
                  Phone Number:{" "}
                  <span className="font-bold">555-545-5444</span>
                </p>
                <p>
                  Email:{" "}
                  <span className="font-bold">anyotheremail@gmail.com</span>
                </p>
              </div>
              <div className="col-span-12 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold bg-gray-200 px-2">
                  Description
                </h3>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a diam eget dolor ornare facilisis. Sed euismod, nisl eget aliquam ultricies, nunc nisl ultricies nunc, eget ultricies nisl nisl.
                </p>
              </div>
              <div className="col-span-12 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold bg-gray-200 px-2">
                  Terms
                </h3>
                <p className="mt-4">
                  Ipsum dolor sit amet, consectetur adipiscing elit. Sed a diam eget dolor ornare facilisis. Sed euismod, nisl eget aliquam ultricies, nunc nisl ultricies nunc, eget ultricies nisl nisl.
                </p>
              </div>
              <div className="col-span-6 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold bg-gray-200 px-2">
                  Start Date
                </h3>
                <p className="mt-4">{formatDate(start, 1)}</p>
              </div>
              <div className="col-span-6 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold bg-gray-200 px-2">
                  End Date
                </h3>
                <p className="mt-4">{formatDate(end, 1)}</p>
              </div>             

              <div className="col-span-6 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold bg-gray-200 px-2">
                  Deposits
                </h3>
                <p className="mt-4">
                  Security Deposit:{" "}
                  <span className="font-bold">$250</span>
                </p>
              </div>
              <div className="col-span-6 p-4 text-gray-500">
                <h3 className="text-2xl font-semibold bg-gray-200 px-2">
                  Fees
                </h3>
                <p className="mt-4">
                  License Fee Per Month:{" "}
                  <span className="font-bold">
                    $2500.00
                  </span>
                </p>
                <p>
                  Renter Fee:{" "}
                  <span className="font-bold mr-1">
                    $250.00
                  </span>
                  (Person 2 Pays)
                </p>
                <p>
                  Provider Fee:{" "}
                  <span className="font-bold mr-1">
                    $65.00
                  </span>
                  (Person 1 Pays)
                </p>
              </div>
              <div className="col-span-12 p-4 text-gray-500">
                <p>
                  **Payment must be made via the{" "}
                  <span className="font-bold">TechBret platform</span>**
                </p>
              </div>
              <div className="col-span-12 p-4 text-gray-500">
                <p>
                  Location Address{" "}
                  <span className="font-bold underline capitalize">
                    123 Main St. Anytown, USA 12345
                  </span>
                </p>
              </div>
              <div className="col-span-12 p-4 text-gray-500">
                <p>
                  This License Agreement (“Agreement”) is entered into on this 3rd
                  day of January, 2023 (the “Effective Date”) between the above listed
                  Person 1 and Person 2 collectively referred to in this
                  Agreement as the "<span className="font-bold">Parties</span>,"
                  or individually, a "<span className="font-bold">Party</span>".
                  This Agreement includes and incorporates the above License
                  Form, as well as the attached Terms and Conditions, and
                  Exhibits, and contains, among other things, warranty
                  disclaimers, liability limitations and use limitations. By
                  signing this Agreement, you agree to these terms and
                  conditions. If you do not agree to these terms and conditions,
                  do not sign this Agreement.
                </p>
              </div>
              <div className="col-span-6 p-4 text-gray-500">
                <p>
                  By:{" "}
                  <span className="font-bold">anyemail@gmail.com</span>
                </p>
                <p>
                  Name:{" "}
                  <span className="font-bold">
                    Joe Smith
                  </span>
                </p>
                <p>
                  Title: <span className="font-bold">Person 1</span>
                </p>
                <p>
                  Date:{" "}
                  <span className="font-bold">{formatDate(new Date())}</span>
                </p>
                {providerSigns ? (
                  <>
                    <div className="border-2">
                      <ReactSignatureCanvas
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        ref={signPad}
                      />
                    </div>
                    <p className="text-center">Sign Here</p>
                    <div className="text-right">
                      <button
                        className="ml-auto text-xs border px-3 py-1 hover:bg-gray-200"
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                      <button
                        className="text-xs border px-3 py-1 hover:bg-gray-200"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : sigSaved ? (
                  <>
                    <img alt="signature" src={signature} />
                    <div className="flex items-center border-l-4 border-red-400 mt-8">
                      <input
                        type="text"
                        placeholder="Type your name"
                        className="border-2 w-1/2 ml-4"
                        onChange={(e) => setProviderTyped(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <button
                    className={`${
                      user?.id === contract?.tenantId
                        ? "bg-gray-300"
                        : "bg-blue-500 hover:bg-blue-700"
                    } text-white font-bold py-2 px-4 rounded mt-4`}
                    disabled={user?.id === contract?.tenantId}
                    onClick={() => setProviderSigns(true)}
                  >
                    {user?.id !== contract?.tenantId
                      ? "Sign Now"
                      : "Person 1's Signature"}
                  </button>
                )}
              </div>
              <div className="col-span-6 p-4 text-gray-500">
                <p>
                  By:{" "}
                  <span className="font-bold">anyotheremail@gmail.com</span>
                </p>
                <p>
                  Name:{" "}
                  <span className="font-bold">
                    Jamie Jones
                  </span>
                </p>
                <p>
                  Title: <span className="font-bold">Person 2</span>
                </p>
                <p>
                  Date:{" "}
                  <span className="font-bold">{formatDate(new Date())}</span>
                </p>
                {tenantSigns ? (
                  <>
                    <div className="border-2">
                      <ReactSignatureCanvas
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        ref={signPad}
                      />
                    </div>
                    <p className="text-center">Sign Here</p>
                    <div className="text-right">
                      <button
                        className="ml-auto text-xs border px-3 py-1 hover:bg-gray-200"
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                      <button
                        className="text-xs border px-3 py-1 hover:bg-gray-200"
                        onClick={handleRenterSave}
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : rentSigSaved ? (
                  <>
                    <img alt="signature" src={signature} />
                    <div className="flex items-center border-l-4 border-red-400 mt-8">
                      <input
                        type="text"
                        placeholder="Type your name"
                        className="border-2 w-1/2 ml-4"
                        onChange={(e) => setTenantTyped(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <button
                    className={`${
                      user?.id === contract?.tenantId
                        ? "bg-blue-500 hover:bg-blue-700"
                        : "bg-gray-300"
                    } text-white font-bold py-2 px-4 rounded mt-4`}
                    disabled={user?.id !== contract?.tenantId}
                    onClick={() => setTenantSigns(true)}
                  >
                    {user?.id === contract?.tenantId
                      ? "Sign Now"
                      : "Renter's Signature"}
                  </button>
                )}
              </div>
           
            </div>
            <div className="flex items-center mt-8 border-l-4 border-red-400">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ml-4"
                onChange={handleGetIP}
              />
              <label className="ml-2 block text-sm leading-5 text-gray-900">
                I hereby acknowledge that I have read and understood the above
                conditions.
              </label>
            </div>
            <div className="flex items-center justify-center mt-8">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              >
                Sign The Lease Agreement
              </button>
            </div>
          </div>
        </div>
      </div>      
    </div>
    </div>
  </div>
  )
}
