import React, { useState } from "react";
import Samples from "../components/Samples";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Portfolio() {
  const [tabs, setTabs] = useState([
    { name: "Pricing Calculator", href: "#", current: true },
    { name: "Secured Routing", href: "#", current: false },
    { name: "Quiz", href: "#", current: false },
    { name: "Database Operations", href: "#", current: false },
    { name: "Graphs", href: "#", current: false },
    { name: "Image Uploader", href: "#", current: false },
    { name: "Contracts", href: "#", current: false },
   
    // { name: "Maps and Pins", href: "#", current: false },
    
  ]);
  const [display, setDisplay] = useState("Pricing Calculator");


 const handleSetCurrent = (name) => {
    const newTabs = tabs.map((tab) => {
      if (tab.name === name) {
        tab.current = true;
      } else {
        tab.current = false;
      }
      return tab;
    });
    setTabs(newTabs);
    setDisplay(name);
  };

  return (
    <div id="port">
      <div className="px-6 pt-24 sm:px-6  lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-5xl font-bold text-sky-400 sm:text-6xl font-cub">
            Component Samples
          </h1>
          <h2 className="text-3xl font-bold text-white sm:text-4xl font-cub mt-2">
            Take a look at some of my work
          </h2>
          <p className="mx-auto mt-2 max-w-xl text-lg text-zinc-200 font-sof">
            I've compiled a few of my favorite components and code samples for you
            to take a look at.
          </p>
        </div>
      </div>
      <div className="max-w-7xl mx-auto mt-8 font-cub ">
        <div className="lg:hidden text-zinc-950 px-6">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs.find((tab) => tab.current).name}
            onChange={(event) => handleSetCurrent(event.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden lg:block  ">
          <nav
            className="flex items-center justify-center space-x-4 bg-indigo-500 rounded-md p-2"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={classNames(
                  tab.current
                    ? "bg-white text-sky-950 shadow-sm shadow-zinc-800"
                    : "text-zinc-950 hover:text-white",
                  "rounded-md px-3 py-2 text-sm font-medium hover:drop-shadow-[0_0_12px_rgba(253,56,252,1)] hover:scale-105 transition-transform"
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={() => handleSetCurrent(tab.name)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
          
        </div>
        <p className="text-center text-gray-300 text-md mt-2">
            Click on a tab to view the sample.
          </p>
      </div>
      <Samples display={display} />
     
    </div>
  );
}
