import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import Main from "./Main";

export default function SubscriberRoutes() {
  return (
    <div>
      <Dashboard>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/subscribers/:id" element={<h1>Subscriber</h1>} />
          <Route path="/*" element={<Main />} />
        </Routes>
      </Dashboard>
    </div>
  );
}
