import React, { useEffect } from "react";

import Login from "../components/Login";
import { useParams } from "react-router-dom";
import CreateAccount from "../components/CreateAccount";

export default function SecuredRouting() {
  const { page } = useParams();

  const handlePageRouting = () => {
    if (page === "create-user") {
      return <CreateAccount />;
    } else {
      return <Login />;
    }
  };

  useEffect(() => {
    handlePageRouting();
  }, [page]);

  return (
    <div className="grid sm:grid-cols-4 gap-4 gap-y-10 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2 mt-36 items-center">
      <div className="col-span-2 -mt-24 font-cub">
        {handlePageRouting()}
      </div>
      <div className="col-span-2">
        <h1 className="text-4xl  font-cub">Secured Routing</h1>
        <p className="font-sof text-gray-100 text-lg">
          This is a simple example of how to create a secured route in React.
          This page is only accessible if you are logged in. If you are not
          logged in, you will be redirected to the login page. This is a great
          way to protect sensitive data from being accessed by unauthorized
          users.
        </p>
        <h3 className="text-2xl font-cub mt-12">How does it work?</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          This page is wrapped in a component called <code>PrivateRoute</code>.
          This component checks to see if the user is logged in. If they are, it
          will render the component that you passed in. If they are not, it will
          redirect them to the login page. This is a great way to protect
          sensitive data from being accessed by unauthorized users.
        </p>
      </div>
    </div>
  );
}
