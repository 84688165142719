import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { UserAuth } from "./context/AuthContext";
import Loading from "./components/Loading";
import SubscriberRoutes from "./subscribers/SubscriberRoutes";

export default function App() {
  const { isLoggedOut, isLoading } = UserAuth();
  return (
    <div>
      <Suspense fallback={<Loading />}>
        {isLoggedOut ? (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:page" element={<Home />} />
            <Route path="/*" element={<Home />} />
          </Routes>
        ) : (
          <div>
            {isLoading ? <Loading /> : <SubscriberRoutes />}
          </div>
        )}
      </Suspense>
    </div>
  );
}
