import React from "react";

export default function MapsAndPins() {
  return (
    <div className="grid sm:grid-cols-4 gap-4 gap-y-10 grid-cols-1 max-w-7xl mx-auto sm:px-8 px-2 mt-36 items-center">
      <div className="col-span-2 -mt-24 font-cub"></div>
      <div className="col-span-2">
        <h1 className="text-4xl  font-cub">Maps and Pins</h1>
        <p className="font-sof text-gray-100 text-lg">
          This is a simple map with pins. You can click on a pin to see more
          information about that location. This is a great way to show your
          customers where your business is located.
        </p>
        <h3 className="text-2xl font-cub mt-12">How does it work?</h3>
        <p className="font-sof text-lg text-gray-100 text-left">
          I use a library called{" "}
          <a
            className="text-blue-500"
            href="https://www.npmjs.com/package/react-map-gl"
          >
            react-map-gl
          </a>{" "}
          to display the map. This library allows you to add pins to the map and
          display information about the pin when you click on it. I then also
          save the pin information in the database. When the pin is clicked,
          this page will capture the users IP address and save it in the
          database. This way, if there is ever a dispute, you can verify that
          the pin was clicked from the same IP address that the pin was sent to.
        </p>
      </div>
    </div>
  );
}
